@import '~@thumbtack/thumbprint-tokens/dist/scss/_index';

.root {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
}

.dot {
    border-radius: $tp-border-radius__full;
    animation: tp-loader-animation-1 1.3s infinite ease-in;
    opacity: 0;
    -moz-transform: rotate(0.1deg); // Fixes small rendering bug in FF;
}

.dot:nth-child(2) {
    animation-name: tp-loader-animation-2;
}

.dot:nth-child(3) {
    animation-name: tp-loader-animation-3;
}

.dotSizeMedium {
    width: 16px;
    height: 16px;
}

.dotSizeMedium:nth-child(2) {
    margin-left: 10px;
    margin-right: 10px;
}

.dotSizeSmall {
    width: 8px;
    height: 8px;
}

.dotSizeSmall:nth-child(2) {
    margin-left: 5px;
    margin-right: 5px;
}

.dotThemeBrand {
    background-color: $tp-color__blue;
}

.dotThemeInverse {
    background-color: $tp-color__white;
}

.dotThemeMuted {
    background-color: $tp-color__black-300;
}

/**
 * Text only visible to screenreaders.
 * http://webaim.org/techniques/css/invisiblecontent/
 * TODO: Potentially abstract into a reusable component.
 */
.hiddenText {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

@keyframes tp-loader-animation-1 {
    0%,
    70%,
    100% {
        opacity: 0.2;
    }
    30% {
        opacity: 1;
    }
}

@keyframes tp-loader-animation-2 {
    0%,
    15%,
    85%,
    100% {
        opacity: 0.2;
    }
    45% {
        opacity: 1;
    }
}

@keyframes tp-loader-animation-3 {
    0%,
    30%,
    100% {
        opacity: 0.2;
    }
    60% {
        opacity: 1;
    }
}
